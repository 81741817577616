import { useBaseProps } from "./useBaseProps";

export const Messaging = {
  // buy button
  BUY_BUTTON_CTA: "Buy",
  BUY_BUTTON_WITH_ETH_CTA: "Buy with ETH",
  BUY_BUTTON_WITH_CARD_CTA: "Buy with Credit Card",
  BUY_BUTTON_SALE_NOT_STARTED: "Starting Soon",
  BUY_BUTTON_SOLD_OUT: "Sold Out",
  BUY_BUTTON_SALE_ENDED: "Sale Ended",
  BUY_BUTTON_CONNECT_WALLET_TO_BUY: "Connect wallet to buy",
  BUY_BUTTON_PRESALE_ELIGIBILITY: "Preale Eligible",
  BUY_BUTTON_PRESALE_INELIGIBILITY: "Presale Ineligible",
  BUY_BUTTON_PRESALE_ALREADY_MINTED: "Presale Minted",
  BUY_BUTTON_CHECKING_ELIGIBILITY: "Checking Eligibility",
  BUY_BUTTON_PURCHASE_LIMIT_REACHED: "Purchase Limit Reached",
  BUY_BUTTON_NOT_ENOUGH_ETH: "Not Enough ETH",
  // claim button
  CLAIM_BUTTON_CTA: "Claim",
  CLAIM_BUTTON_INELIGIBILITY: "Claim Ineligible",
  CLAIM_BUTTON_ALREADY_MINTED: "Claim Minted",

  CLAIM_BUTTON_CLAIM_NOT_STARTED: "Claim opens soon",
  CLAIM_BUTTON_CONNECT_WALLET: "Connect wallet to claim",
  // purchase amount
  ONE_TIME_PRESALE_MINT_DISCLAIMER: "You can only participate in the presale once",
  PURCHASE_AMOUNT_CTA: "Select Amount to Mint",
  // countdown
  SALE_PHASE_CLAIM_START: "Claim starts",
  SALE_PHASE_CLAIM_END: "Claim ends",
  SALE_PHASE_TO_PRESALE: "Presale starts",
  SALE_PHASE_TO_PUBLIC_SALE: "Sale starts",
  SALE_PHASE_IN_PRESALE: "Presale ends",
  SALE_PHASE_IN_PUBLIC_SALE: "Sale ends",
  SALE_PHASE_EXPIRED: "Sale ended",
  SALE_PHASE_NOT_ACTIVE: "Sale Not Active",
  // dutch auction
  DUTCH_AUCTION_PUBLIC_SALE_NOT_STARTED: "Public Sale not started",
  DUTCH_AUCTION_FINAL_PRICE: "Final Price",
};

export const useMessaging = (): typeof Messaging => {
  const baseProps = useBaseProps();

  return {
    ...Messaging,
    ...(baseProps.messaging || {}),
  };
};
