import manifoldCSSReset from "@manifoldxyz/css-reset";
import {
  Buy,
  ClaimRefund,
  Collectible,
  Countdown,
  CurrentPrice,
  DutchIntervalCountdown,
  Inventory,
} from "@/components";
import CollectibleView from "@/widgets/CollectibleView.vue";
import { studioAppServerAPI } from "./api/StudioAppsServerAPI";
import { renderComponentWithApp } from "./mount";

manifoldCSSReset();

export interface ManifoldWindow extends Window {
  mStore: any;
  analytics: any;
}

/**
 * Begin: Installs Google Recaptch V2
 */

let elLoadCallback = document.createElement("script");
elLoadCallback.innerHTML = "var onloadCallback = function() { window.recaptchaReady = true; };";
document.head.appendChild(elLoadCallback);

const elRecaptchaScript = document.createElement("script");
elRecaptchaScript.setAttribute(
  "src",
  "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
);
elRecaptchaScript.setAttribute("async", "true");
elRecaptchaScript.setAttribute("defer", "true");
document.head.appendChild(elRecaptchaScript);

/**
 * End: Installs Google Recaptch V2
 */

/**
 * Begin: Installs Crossmint Components
 */

elLoadCallback = document.createElement("script");
elLoadCallback.innerHTML = "var onloadCallback = function() { window.recaptchaReady = true; };";
document.head.appendChild(elLoadCallback);

const elCrossmintScript = document.createElement("script");
elCrossmintScript.setAttribute(
  "src",
  "https://unpkg.com/@crossmint/client-sdk-vanilla-ui@0.1.0/lib/index.global.js"
);
document.head.appendChild(elCrossmintScript);

/**
 * End: Installs Crossmint Components
 */

const widgetAttributeToComponentMap = {
  "m-view": CollectibleView,
  "m-buy": Buy,
  "m-collectible": Collectible,
  "m-countdown": Countdown,
  "m-current-price": CurrentPrice,
  "m-dutch-interval-countdown": DutchIntervalCountdown,
  "m-inventory": Inventory,
  "m-claim-refund": ClaimRefund,
};

let widgetAttributeChangeObserver: MutationObserver;
let bodyChangeObserver: MutationObserver;
const renderedComponents = new Map();

/**
 * Replaces the el with a rendered Vue component (as its own Vue app)
 * then monitors that div for any attribute changes, that way we know when
 * to automatically destroy + rerender the widget with new props.
 * This also handles parsing the data-attrs into typechecked Vue props.
 */
const replaceWithWidget = async (el: HTMLElement) => {
  const props = {
    ...(el as HTMLElement).dataset,
  };
  const requireAdditionalPricingData: boolean = props.requireAdditionalPricingData
    ? JSON.parse(props.requireAdditionalPricingData)
    : false;
  // @ts-ignore
  props.requireAdditionalPricingData = requireAdditionalPricingData;

  if (props.collectibleInstanceId) {
    const instance = await studioAppServerAPI.fetchInstance(props.collectibleInstanceId);
    // @ts-ignore
    props.network = instance.publicData.network!;
    props.collectibleAddress = instance.publicData.creatorContractAddress;
    props.extensionAddress = instance.publicData.extensionAddress;
  } else {
    const network = parseInt((el as HTMLElement).dataset.network || "");

    if (Number.isNaN(network)) {
      throw new Error("invalid network");
    }
    // @ts-ignore
    props.network = parseInt(props.network || "");
    props.collectibleAddress = props.address;
  }

  const widget =
    widgetAttributeToComponentMap[
      el.getAttribute("data-widget") as keyof typeof widgetAttributeToComponentMap
    ];
  const renderedComponent = renderComponentWithApp({
    el: el,
    // @ts-ignore
    component: widget,
    props,
    collectibleAddress: props.collectibleAddress!,
  });
  renderedComponents.set(el, renderedComponent);
  // observe any attribute changes and rerender accordingly
  const config = {
    attributes: true,
    childList: false,
    subtree: false,
  };
  widgetAttributeChangeObserver.observe(el, config);
};

/**
 * Checks if the el has a corresponding rendered Vue component in memory.
 * If it does, we unmount the Vue component and destroy its data in memory.
 */
const destroyPotentialWidget = (el: HTMLElement) => {
  const renderedComponentRef = renderedComponents.get(el);
  if (renderedComponentRef) {
    // unmount and destroy the pre-existing Vue app-component for memory's sake
    renderedComponentRef();
    renderedComponents.delete(el);
  }
};

/**
 * When a previously rendered widget has an attribute changed we need to
 * destroy the potential widget that was already rendered in that div
 * and then render a brand new widget that uses the new data as props in
 * its place.
 */
const handleWidgetAttributeChange = async (mutations: MutationRecord[]) => {
  mutations.forEach((mutation) => {
    if (mutation.type === "attributes" && mutation.attributeName !== "data-v-app") {
      // destroy pre-existing app-component before replacing it with new one
      destroyPotentialWidget(mutation.target as HTMLElement);
      replaceWithWidget(mutation.target as HTMLElement);
    }
  });
};

/**
 * Recursively finds and returns all children as an array.
 * The last element of the array is the root element.
 */
export const getAllChildren = (htmlElement: Element): Element[] => {
  if (!htmlElement.children || htmlElement.children?.length === 0) {
    return [htmlElement];
  }

  const allChildElements = [];

  for (let i = 0; i < htmlElement.children.length; i++) {
    const children = getAllChildren(htmlElement.children[i]);
    if (children) allChildElements.push(...children);
  }
  allChildElements.push(htmlElement);

  return allChildElements;
};

/**
 * Whenever the body changes we want to see:
 *
 * 1. If a div with data-widget was added to the DOM
 * 2. If a div with data-widget was removed from the DOM
 * 3. If a div dynamically had the data-widget attr added
 *
 * If any are true, we want to render or destroy the widget in that div.
 */
const handleBodyChanges = async (mutations: MutationRecord[]) => {
  mutations.forEach((mutation) => {
    // dynamically added a node that may have a div with our data-widget attribute inside
    mutation.addedNodes.forEach((node) => {
      const htmlEl = node as HTMLElement;

      if (
        widgetAttributeToComponentMap[
          htmlEl?.dataset?.widget as keyof typeof widgetAttributeToComponentMap
        ]
      ) {
        replaceWithWidget(htmlEl);
        return;
      }

      const children = getAllChildren(htmlEl);
      children.pop(); // last element is root el which we've already checked above

      children.forEach((child) => {
        if (
          widgetAttributeToComponentMap[
            (child as HTMLElement)?.dataset?.widget as keyof typeof widgetAttributeToComponentMap
          ]
        ) {
          replaceWithWidget(child as HTMLElement);
        }
      });
    });

    // dynamically removed a node that had our widget rendered into it
    mutation.removedNodes.forEach((node) => {
      const htmlEl = node as HTMLElement;

      if (
        widgetAttributeToComponentMap[
          htmlEl?.dataset?.widget as keyof typeof widgetAttributeToComponentMap
        ]
      ) {
        destroyPotentialWidget(htmlEl);
        return;
      }

      const children = getAllChildren(htmlEl);
      children.forEach((child) => {
        if (
          widgetAttributeToComponentMap[
            (child as HTMLElement)?.dataset?.widget as keyof typeof widgetAttributeToComponentMap
          ]
        ) {
          destroyPotentialWidget(child as HTMLElement);
        }
      });
    });

    // dynamically added the data-widget attribute
    if (mutation.type === "attributes" && mutation.attributeName === "data-widget") {
      const htmlEl = mutation.target as HTMLElement;
      if (
        widgetAttributeToComponentMap[
          htmlEl?.dataset?.widget as keyof typeof widgetAttributeToComponentMap
        ]
      ) {
        replaceWithWidget(htmlEl);
      }
    }
  });
};

const listen = () => {
  // MutationObserver gets reused by every rendered component
  widgetAttributeChangeObserver = new window.MutationObserver(handleWidgetAttributeChange);
  // manually sweep the DOM for pre-existing widget divs that need replacing
  const elements = document.querySelectorAll(`[data-widget]`);

  elements.forEach((el: Element) => {
    if (
      widgetAttributeToComponentMap[
        (el as HTMLElement).dataset?.widget as keyof typeof widgetAttributeToComponentMap
      ]
    ) {
      replaceWithWidget(el as HTMLElement);
    }
  });

  // Listen for new widget divs being added/removed to the body of the DOM.
  // Also listen for divs dynamically adding the data-widget attribute.
  const bodyNode = document.querySelector("body");
  if (bodyNode && !bodyChangeObserver) {
    const config = {
      attributeFilter: ["data-widget"],
      attributes: true,
      childList: true,
      subtree: true,
    };

    bodyChangeObserver = new window.MutationObserver(handleBodyChanges);
    bodyChangeObserver.observe(bodyNode, config);
  }
};

if (window) {
  // Checks if `load` has already fired so we dont wait indefinitely to call main()
  // ref: https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming/loadEventEnd
  const alreadyLoaded = performance
    .getEntriesByType("navigation")
    .every((e) => (e as PerformanceNavigationTiming).loadEventEnd);

  if (alreadyLoaded) {
    // the `load` event already fired so we're ready to do main() instantly
    listen();
  } else {
    // we only want to call main() after the page `load` fires
    window.addEventListener("load", listen);
  }
}

function prepareExportable(Component: any, componentTag: string) {
  return {
    install(Vue: any) {
      Vue.component(componentTag, Component);
    },
  };
}

export default {
  CollectibleView: prepareExportable(CollectibleView, "m-view"),
  Buy: prepareExportable(Buy, "m-buy"),
  Collectible: prepareExportable(Collectible, "m-collectible"),
  Countdown: prepareExportable(Countdown, "m-countdown"),
  CurrentPrice: prepareExportable(CurrentPrice, "m-current-price"),
  DutchIntervalCountdown: prepareExportable(DutchIntervalCountdown, "m-dutch-interval-countdown"),
  Inventory: prepareExportable(Inventory, "m-inventory"),
  ClaimRefund: prepareExportable(ClaimRefund, "m-claim-refund"),
};
