import { inject } from "vue";
import { ContractType } from "@manifoldxyz/collectible-sdk";
import { EthereumNetwork } from "@manifoldxyz/frontend-provider-types";
import type { Messaging } from "./useMessaging";

type BaseProps = {
  address: string;
  network: EthereumNetwork;
  contractType: ContractType;
  requireAdditionalPricingData?: boolean;
  messaging?: Partial<typeof Messaging>;
};

export const useBaseProps = (): BaseProps => {
  const { address, network, contractType, requireAdditionalPricingData, messaging } = inject(
    "baseProps"
  ) as BaseProps;

  return {
    address,
    network,
    contractType,
    requireAdditionalPricingData,
    // `messaging` is a JSON string.
    messaging: !messaging ? null : JSON.parse(messaging as unknown as string),
  };
};
