import { Contract } from "ethers";
import {
  ContractCallError,
  HardwareWalletError,
  UserRejectedError,
} from "@manifoldxyz/collectible-sdk/dist/lib/errors";

export class TransactionManager {
  constructor(readonly contractInstance: Contract, readonly method: string, readonly args: any) {}

  async estimateGas() {
    const gasEstimate = await this.contractInstance!.estimateGas[this.method](...this.args);

    // Multiply gas estimate by 1.25 to account for inaccurate estimates from Metamask.
    return gasEstimate.mul(125).div(100);
  }

  async send() {
    try {
      return await this.contractInstance![this.method](...this.args);
    } catch (e: any) {
      if (e.code === 4001 || e.code === "ACTION_REJECTED") {
        throw new UserRejectedError("User rejected request");
      }

      if (
        e.message.toLowerCase().search("trezor") >= 0 ||
        e.message.toLowerCase().search("ledger") >= 0
      ) {
        throw new HardwareWalletError(e.message.replace("execution reverted: ", ""));
      }

      throw new ContractCallError(e.message.replace("execution reverted: ", ""));
    }
  }
}
