import { CollectibleInstance } from "@manifoldxyz/collectible-sdk";

const STUDIO_APP_BACKEND = "https://apps.api.manifoldxyz.dev";

export class StudioAppServerAPI {
  private async fetch(path: string, options: any = {}): Promise<Response> {
    let url = STUDIO_APP_BACKEND + path;

    if (options.query) {
      const queryString = new URLSearchParams(options.query).toString();
      url = url + "?" + queryString;
    }

    const requestOptions = {
      method: "GET",
      ...options,
    };

    const response = await fetch(url, requestOptions);
    if (response.status >= 400 && response.status <= 500) {
      console.error(response);
      throw new Error(`Received status '${response.status}' from server`);
    }
    return response;
  }

  async fetchInstance(instanceId: string | number): Promise<{
    publicData: CollectibleInstance;
    creator: {
      id: number;
      name: string;
      twitterUrl: string;
      address: string;
    };
  }> {
    const instanceResponse = await this.fetch(`/public/instance/data?id=${instanceId}`);

    return await instanceResponse.json();
  }
}

export const studioAppServerAPI = new StudioAppServerAPI();
