import { createPinia } from "pinia";
import { createApp } from "vue";
import { CollectibleSDKFactory, ContractType, PricingType } from "@manifoldxyz/collectible-sdk";
import { COLLECTIBLE_BACKEND_MAP } from "@/constants";
import { createStoreSingleton } from "@/store/createStore";

const pinia = createPinia();

export const renderComponentWithApp = ({
  el,
  component,
  props,
  collectibleAddress,
}: {
  collectibleAddress: string;
  el: Element;
  component: Parameters<typeof createApp>[0];
  props: NonNullable<Parameters<typeof createApp>[1]>;
}) => {
  const app = createApp(component, props);
  app.use(pinia);
  const network =
    typeof props.network === "number" ? props.network : parseInt(props.network as string);
  const collectibleServerURL = props.development
    ? "http://localhost:3000"
    : COLLECTIBLE_BACKEND_MAP[network];

  const collectibleSDK = CollectibleSDKFactory.getOrCreateInstance({
    collectibleAddress: collectibleAddress as string,
    sharedExtensionAddress: props.extensionAddress as string,
    collectibleInstanceId: props.collectibleInstanceId
      ? (props.collectibleInstanceId as string)
      : undefined,
    network,
    contractType: props.contractType as ContractType,
    pricingType: props.requireAdditionalPricingData ? PricingType.DUTCH_AUCTION : undefined,
    collectibleServerURL,
    appName: props.appName as string,
    clientId: props.clientId as string,
    authMessage: (props.authMessage as string) || "Please sign this message to access Presale",
  });

  const store = createStoreSingleton(
    collectibleAddress,
    collectibleSDK,
    props.crossmintClientId as string
  );

  app.provide("baseProps", props);
  app.provide("collectibleSDK", collectibleSDK);
  app.provide("store", store);

  app.mount(el);

  return app;
};
