import { BigNumber, BigNumberish, utils } from "ethers";
import { defineStore } from "pinia";
import type { CollectibleSDK } from "@manifoldxyz/collectible-sdk";
import {
  CollectibleState,
  ContractType,
  PricingData,
  PricingType,
  RefundData,
  SalePhase,
} from "@manifoldxyz/collectible-sdk";
import { getCountdownFromDuration, getCountdownFromTimestamp } from "@manifoldxyz/dropsite-lib";
import {
  CollectibleERC721Contract,
  CollectibleERC721SharedContract,
  CollectibleERC1155Contract,
  DutchAuctionContract,
} from "@manifoldxyz/typed-contracts/dist/contracts/ethers";

export type State = {
  collectibleAddress: string;
  now: number;
  // provider
  providerAvailable: boolean;
  walletAvailable: boolean;
  walletAddress: string;
  walletBalance: string;
  wrongChain: boolean;
  // collectible
  loaded: boolean;
  active: boolean;
  finalized: boolean;
  startTime: Date;
  endTime: Date;
  presaleEndTime: Date;
  offset: number;
  offsetLoaded: boolean;
  tokenMax: number;
  tokenRemaining: number;
  purchaseLimit: number;
  presalePurchaseLimit: number;
  purchaseCount: number;
  presalePrice: BigNumber;
  price: BigNumber;
  pricingType: PricingType;
  pricingData: PricingData;
  refundData: RefundData;
  transactionLimit: number;
  contractType: ContractType;
  claimStartTime: Date;
  claimEndTime: Date;
  useDynamicPresalePurchaseLimit: boolean;
  collectibleState: CollectibleState;
  salePhase: SalePhase;
  contract:
    | CollectibleERC1155Contract
    | CollectibleERC721Contract
    | DutchAuctionContract
    | CollectibleERC721SharedContract;
  count: number;
  crossmintClientId: string;
  isFetchingPresaleEligibility: boolean;
  purchaseableAmount: number;
  isFetchingClaimEligibility: boolean;
  claimEligibility: CollectibleState["claimEligibility"];
  presaleEligibility: CollectibleState["presaleEligibility"];
};

// @ts-ignore
export const state: State = {
  now: Date.now(),
  collectibleAddress: "",
  // provider state
  providerAvailable: false,
  walletAvailable: "ethereum" in window,
  walletAddress: "",
  walletBalance: "0",
  wrongChain: false,
  // collectible state
  loaded: false,
  active: false,
  finalized: false,
  startTime: new Date(),
  endTime: new Date(),
  presaleEndTime: new Date(),
  offset: 0,
  offsetLoaded: false,
  tokenMax: 0,
  tokenRemaining: 0,
  purchaseLimit: 0,
  presalePurchaseLimit: 0,
  purchaseCount: 0,
  presalePrice: BigNumber.from("0"),
  price: BigNumber.from("0"),
  pricingType: PricingType.DEFAULT,
  pricingData: {
    type: PricingType.DEFAULT,
  },
  refundData: {
    type: PricingType.DEFAULT,
  },
  transactionLimit: 0,
  contractType: "erc721",
  claimStartTime: new Date(),
  claimEndTime: new Date(),
  useDynamicPresalePurchaseLimit: false,
  salePhase: SalePhase.LOADING,
  crossmintClientId: "",
  isFetchingPresaleEligibility: false,
  purchaseableAmount: 0,
  isFetchingClaimEligibility: false,
  claimEligibility: null,
  presaleEligibility: null,
};

export const useStoreAtAddress = (address: string) => {
  const useStore = defineStore({
    id: address,
    state: (): State => Object.assign({}, state),
    actions: {
      setNow(timestamp: number) {
        this.now = timestamp;
      },
      setProviderAvailable(isProviderAvailable: boolean) {
        this.providerAvailable = isProviderAvailable;
      },
      setWalletAvailable(isWalletAvailable: boolean) {
        this.walletAvailable = isWalletAvailable;
      },
      setWalletAddress(walletAddress: string) {
        this.walletAddress = walletAddress;
      },
      setWalletBalance(walletBalance?: BigNumberish) {
        if (walletBalance) {
          const balance = utils.formatUnits(walletBalance).split(".");
          this.walletBalance = `${balance[0]}.${balance[1].slice(0, 4)}`;
        } else {
          // @ts-ignore
          this.walletBalance = undefined;
        }
      },
      setWrongChain(isWrongChain: boolean) {
        this.wrongChain = isWrongChain;
      },
      setCollectibleState(collectibleState: CollectibleState, collectibleSDK: CollectibleSDK) {
        this.collectibleState = collectibleState;
        this.active = collectibleState.active;
        this.startTime = collectibleState.startTime!;
        this.endTime = collectibleState.endTime!;
        this.claimStartTime = collectibleState.claimStartTime;
        this.claimEndTime = collectibleState.claimEndTime;
        this.presaleEndTime = collectibleState.presaleEndTime!;
        this.offset = collectibleState.offset;
        this.offsetLoaded = collectibleState.offsetLoaded;
        this.tokenMax = BigNumber.from(collectibleState.purchaseMax).toNumber();
        this.tokenRemaining = collectibleState.tokensRemaining;
        this.purchaseLimit = BigNumber.from(collectibleState.purchaseLimit || "0").toNumber();
        this.presalePurchaseLimit = BigNumber.from(
          collectibleState.presalePurchaseLimit || "0"
        ).toNumber();
        this.purchaseCount = BigNumber.from(collectibleState.purchaseCount || "0").toNumber();
        this.presalePrice = BigNumber.from(collectibleState.presalePurchasePrice || "0");
        this.price = BigNumber.from(collectibleState.currentPrice);
        this.pricingType = collectibleState.pricingType;
        this.pricingData = collectibleState.pricingData;
        this.transactionLimit = BigNumber.from(collectibleState.transactionLimit || "0").toNumber();
        this.contractType = collectibleState.contractType;
        this.useDynamicPresalePurchaseLimit = collectibleState.useDynamicPresalePurchaseLimit;
        this.isFetchingPresaleEligibility = collectibleState.isFetchingPresaleEligibility;
        this.salePhase = collectibleState.salePhase!;
        this.loaded = !collectibleState.isLoading;
        this.contract = collectibleSDK.contractInstance;
        this.purchaseableAmount = collectibleState.purchaseableAmount;
        this.isFetchingClaimEligibility = collectibleState.isFetchingClaimEligibility;
        this.claimEligibility = collectibleState.claimEligibility;
        this.presaleEligibility = collectibleState.presaleEligibility;
      },
      setRefundData(refundData: RefundData) {
        this.refundData = refundData;
      },
      setCollectibleAddress(collectibleAddress: string) {
        this.collectibleAddress = collectibleAddress;
      },
      setCrossmintClientId(crossmintClientId: string) {
        this.crossmintClientId = crossmintClientId;
      },
    },
    getters: {
      isERC1155({ contractType }): boolean {
        return Boolean(contractType === "erc1155");
      },
      isERC721({ contractType }): boolean {
        return contractType === "erc721";
      },
      mintCountdown({
        salePhase,
        offset,
        claimStartTime,
        claimEndTime,
        presaleEndTime,
        endTime,
        startTime,
        // Include "now" so that this getter refreshes whenever "now" timestmap is updated
        now: _now,
      }): ReturnType<typeof getCountdownFromTimestamp> {
        if (salePhase) {
          if (
            salePhase === SalePhase.LOADING ||
            salePhase === SalePhase.EXPIRED ||
            salePhase === SalePhase.DEACTIVATED
          ) {
            return getCountdownFromTimestamp(0, true, false, offset) as string;
          } else {
            let timestamp;
            if (salePhase === SalePhase.TO_CLAIM) {
              timestamp = claimStartTime;
            } else if (salePhase === SalePhase.CLAIM) {
              timestamp = claimEndTime;
            } else if (salePhase === SalePhase.PRESALE) {
              timestamp = presaleEndTime;
            } else if (salePhase === SalePhase.PUBLIC_SALE) {
              timestamp = endTime;
            } else {
              timestamp = startTime;
            }
            return getCountdownFromTimestamp(timestamp!, true, false, offset) as string;
          }
        } else {
          return getCountdownFromTimestamp(0, true, false, offset) as string;
        }
      },
      dutchAuctionTimeElapsed({ salePhase, now, presaleEndTime, pricingData }): BigNumber {
        if (
          pricingData?.type !== PricingType.DUTCH_AUCTION ||
          salePhase !== SalePhase.PUBLIC_SALE
        ) {
          return BigNumber.from(0);
        }
        if (!this.saleHasEnded) {
          return BigNumber.from(now - presaleEndTime!.getTime() + 1000).div(1000);
        }
        return BigNumber.from(0);
      },
      dutchAuctionCountdown({ pricingData }): ReturnType<typeof getCountdownFromDuration> {
        if (
          pricingData?.type === PricingType.DUTCH_AUCTION &&
          !this.saleHasEnded &&
          pricingData.priceDropInterval
        ) {
          const timeToNextInterval = BigNumber.from(pricingData.priceDropInterval).sub(
            this.dutchAuctionTimeElapsed.mod(pricingData.priceDropInterval)
          );
          return getCountdownFromDuration(timeToNextInterval.mul(1000).toNumber(), false, false);
        }
        return getCountdownFromDuration(0, false, false);
      },
      dutchAuctionHasNotStarted({ salePhase }) {
        return Boolean(
          salePhase === SalePhase.TO_CLAIM ||
            salePhase === SalePhase.TO_PRESALE ||
            salePhase === SalePhase.TO_PUBLIC_SALE ||
            salePhase === SalePhase.CLAIM ||
            salePhase === SalePhase.PRESALE
        );
      },
      dutchAuctionCountdownHasExpired({ pricingData }): boolean {
        if (pricingData?.type === PricingType.DUTCH_AUCTION) {
          return pricingData.minimumPrice! === this.price;
        }
        return true;
      },
      saleHasStarted({ salePhase }): boolean {
        return Boolean(
          salePhase === SalePhase.CLAIM ||
            salePhase === SalePhase.PRESALE ||
            salePhase === SalePhase.PUBLIC_SALE
        );
      },
      saleHasEnded({ endTime, salePhase }): boolean {
        // If endTime is set and salePhase is 'expired'
        // then the sale has ended.
        return Boolean(endTime && salePhase === SalePhase.EXPIRED);
      },
      saleIsActive(): boolean {
        return Boolean(this.saleHasStarted && !this.saleHasEnded && !this.saleIsFinalized);
      },
      presaleIsActive({ salePhase }): boolean {
        return salePhase === SalePhase.PRESALE;
      },
      saleIsFinalized({ tokenRemaining }): boolean {
        return Boolean(tokenRemaining === 0 || this.saleHasEnded);
      },
      purchaseableAmount({ collectibleState }): number {
        return collectibleState.purchaseableAmount;
      },
      userHasEnoughForPurchase({ walletBalance, walletAddress, crossmintClientId }): boolean {
        return (
          (Boolean(walletAddress && walletBalance) &&
            utils.parseEther(walletBalance || "0").gt(this.price)) ||
          Boolean(crossmintClientId)
        );
      },
      userCanPurchase({
        salePhase,
        crossmintClientId,
        purchaseableAmount,
        walletBalance,
        walletAddress,
        presaleEligibility,
      }): boolean {
        return Boolean(
          (walletAddress || crossmintClientId) &&
            (walletBalance || crossmintClientId) &&
            this.userHasEnoughForPurchase &&
            purchaseableAmount > 0 &&
            this.saleIsActive &&
            (salePhase !== SalePhase.PRESALE || presaleEligibility?.isEligibleForPresale)
        );
      },
      isDisabled(): boolean {
        return !this.saleIsActive || this.saleHasEnded;
      },
      walletConnected({ walletAddress }) {
        return Boolean(walletAddress);
      },
    },
  });
  const store = useStore();
  return store;
};
